import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Some of my favorite books :)`}</p>
    <h2>{`Fiction`}</h2>
    <h3>{`Norwegian Wood by Haruki Murakami`}</h3>
    <h3>{`Dune`}</h3>
    <h3>{`Dune Messiah`}</h3>
    <h3>{`Children of Dune`}</h3>
    <h3>{`Dune Emperor`}</h3>
    <h3>{`Heretics of Dune`}</h3>
    <h3>{`Chapterhouse Dune`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      